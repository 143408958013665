import React, { useEffect, useState } from "react";
import firebase from "../../firebase";
import Auth from "../Auth";
import Loading from "../Loading";
import MyQuiz from "./MyQuiz";
import MyNice from "./MyNice";
import MyContribution from "./MyContribution";

const MyPage = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [totalNice, setTotalNice] = useState(0);
  const [totalResponder, setTotalResponder] = useState(0);

  const logout = () => {
    firebase.auth().signOut();
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setUser(user);
      setLoading(false);
    });
  }, [user, loading]);

  if (loading) return <Loading boolean={false} />;
  return (
    <div className="p-myPage">
      <p className="p-myPage__heading wrapper">
        {user && `${user.displayName}`}
      </p>
      {user && (
        <MyContribution totalResponder={totalResponder} totalNice={totalNice} />
      )}
      {user && (
        <MyQuiz
          setTotalResponder={setTotalResponder}
          setTotalNice={setTotalNice}
        />
      )}
      {user && <MyNice />}
      <div className="p-myPage__logout">
        {user ? (
          <button onClick={logout} className="p-myPage__logoutButton">
            Logout
          </button>
        ) : (
          <Auth />
        )}
      </div>
    </div>
  );
};

export default MyPage;
