import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isExist, hasValue } from "../../lib/Expansion";

import { db } from "../../firebase/index";
import Loading from "../Loading";

const QuizList = () => {
  const [quizList, setQuizList] = useState({});
  const [responders, setResponders] = useState();
  const [nice, setNice] = useState();
  useEffect(() => {
    const collection = db
      .collection("quiz")
      .where("limited", "==", false)
      .orderBy("createdAt", "desc")
      .limit(10);
    const items = [];

    collection
      .get()
      .then(querySnapShot => {
        querySnapShot.forEach(doc => {
          items.push(doc.data());
        });
      })
      .then(() => {
        setQuizList({ items });
      })
      .catch(error => {
        console.log("error:", error);
      });
  }, []);

  useEffect(() => {
    if (isExist(quizList)) {
      const collection = db
        .collection("responder")
        .orderBy("createdAt", "desc")
        .limit(10);
      const items = [];

      collection
        .get()
        .then(querySnapShot => {
          querySnapShot.forEach(doc => {
            items.push(doc.data());
          });
        })
        .then(() => {
          setResponders({ ...items });
        })
        .catch(error => {
          console.log("error:", error);
        });
    }
  }, [quizList]);

  useEffect(() => {
    const nice = db.collectionGroup("count");
    const items = [];
    nice
      .get()
      .then(querySnapShot => {
        querySnapShot.forEach(doc => {
          items.push(doc.data());
        });
      })
      .then(() => {
        setNice({ ...items });
      })
      .catch(error => {
        console.log("error:", error);
      });
  }, []);

  const listItem = quizList.items ? quizList.items : [];
  const list = listItem.map(list => (
    <li className="p-quizList__listBox" key={list.docId}>
      <div className="l-wrapper">
        <Link to={`/answer/${list.docId}`} className="p-quizList__listWrap">
          <div className="p-quizList__listInfo">
            <p className="p-quizList__listTitle u-textM">{list.title}</p>
            <p className="p-quizList__listDescription">{list.description}</p>
            <p className="p-quizList__listDetail--Visiter u-textXS">
              {hasValue(responders, list.docId)}
            </p>
            <p className="p-quizList__listDetail--Nice u-textXS">
              {hasValue(nice, list.docId)}
            </p>
          </div>
          <i className="fas fa-chevron-right"></i>
        </Link>
      </div>
    </li>
  ));
  return (
    <>
      <Loading boolean={isExist(listItem)} />
      <p className="l-wrapper p-quizList__title" id="search">
        最近のクイズ
      </p>
      <ul className="p-quizList__list">{quizList.items ? list : ""}</ul>
    </>
  );
};

export default QuizList;
