import React from "react";
import Footer from "../Footer";

export const Contact = () => (
  <>
    <div className="p-contact">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLScxiZn8ywyBVX984xN5mPdaIQ_fxmFsEcAVfrSPDDgKgnYDcg/viewform?embedded=true"
        width="100%"
        height="1000"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="contactForm"
      >
        読み込んでいます…
      </iframe>
    </div>
    <Footer />
  </>
);

export default Contact;
