import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isExist } from "../../lib/Expansion";
import { db } from "../../firebase";

const SearchKeywords = () => {
  const [queryResult, setQueryResult] = useState({});
  const [searchWord, setSearchWord] = useState("");
  const [loadingText, setLoadingText] = useState("");

  const onBlurEvent = () => {
    !isExist(listItem)
      ? setLoadingText("見つかりませんでした！")
      : setLoadingText("");
  };

  useEffect(() => {
    if (queryResult.items) {
      setLoadingText("");
    }
  }, [queryResult]);

  useEffect(() => {
    if (isExist(searchWord)) {
      const docRef = db.collection("quiz").orderBy("title");
      const items = [];
      docRef
        .where("limited", "==", false)
        .startAt(searchWord)
        .endAt(searchWord + "\uf8ff")
        .limit(5)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            items.push(doc.data());
          });
        })
        .then(() => {
          setQueryResult({ items });
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    }
  }, [searchWord]);

  const listItem = queryResult.items ? queryResult.items : [];
  const list = listItem.map(list => (
    <li className="p-searchKeyWords__listBox" key={list.docId}>
      <div className="l-wrapper">
        <Link
          to={`/answer/${list.docId}`}
          className="p-searchKeyWords__listWrap"
        >
          <div className="p-searchKeyWords__listInfo">
            <p className="p-searchKeyWords__listTitle u-textS">{list.title}</p>
          </div>
          <i className="fas fa-chevron-right"></i>
        </Link>
      </div>
    </li>
  ));
  return (
    <>
      <div className="l-wrapper p-searchKeyWords">
        <label htmlFor="keywords" className="p-searchKeyWords__label">
          キーワードで探す
        </label>
        <input
          className="p-searchKeyWords__input"
          type="text"
          id="keywords"
          name="keywords"
          onChange={e => setSearchWord(e.target.value)}
          onBlur={() => {
            onBlurEvent();
          }}
        />
        {loadingText}
        <ul className="p-searchKeyWords__list">
          {queryResult.items ? list : ""}
        </ul>
      </div>
    </>
  );
};

export default SearchKeywords;
