import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import firebase, { db } from "../../firebase";
import ShareButton from "../ShareButton";
import { roundDigit } from "../../lib/Expansion";
import NotFound from "../NotFound";

const QuizAnswerFinish = history => {
  const answerPercentage = history.location.state
    ? roundDigit(
        (history.location.state.correctCount /
          (history.location.state.answerCount + 1)) *
          100,
        1
      )
    : "";
  const totalPercentage =
    history.location.state && history.location.state.totalPercentage;
  const answerData = history.location.state
    ? history.location.state.answerData
    : [];

  const quiz = history.location.state && history.location.state.quiz;

  const resultText = answerData.map((data, index) => {
    const result = data ? "正解" : "不正解";
    return (
      <div key={index}>
        <input
          type="checkbox"
          id={`label${index + 1}`}
          className="p-quizAnswerFinish__resultCheck"
        />
        <label
          htmlFor={`label${index + 1}`}
          className="u-textM p-quizAnswerFinish__resultLabel"
        >
          {index + 1}問目:{" "}
          <span className="p-quizAnswerFinish__resultEnphasis">{result}</span>
        </label>
        <div className="p-quizAnswerFinish__resultTextBox">
          <p className="u-textS">{quiz[index].question}</p>
          <p className="p-quizAnswerFinish__resultTextQuiz u-textS">
            答え：
            <span className="p-quizAnswerFinish__resultEnphasis">
              {quiz[index].answer}
            </span>
          </p>
          <p className="u-textXS p-quizAnswerFinish__resultTextDescription">
            {quiz[index].explanation && `解説: ${quiz[index].explanation}`}
          </p>
        </div>
      </div>
    );
  });

  useEffect(() => {
    const setAnswerPercentage = () => {
      if (!history.location.state) {
        return;
      }
      const answerRef = db
        .collection("quiz")
        .doc(history.location.state.id)
        .collection("answerScore");
      try {
        answerData.forEach((data, index) => {
          const answerDoc = answerRef.doc(`question[${index + 1}]`);
          answerDoc.get().then(answer => {
            if (answer.exists) {
              if (data) {
                answerDoc.update({
                  true: firebase.firestore.FieldValue.increment(1)
                });
              } else {
                answerDoc.update({
                  false: firebase.firestore.FieldValue.increment(1)
                });
              }
            } else {
              answerDoc.set({
                true: 0,
                false: 0
              });
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    };

    setAnswerPercentage();
  }, [answerData, history]);

  if (!history.location.state) return <NotFound />;
  return (
    <div className="p-quizAnswerFinish__wrap l-wrapper">
      <h2 className="p-quizAnswerFinish__scoreTitle u-textM">全体正解率</h2>
      <p className="p-quizAnswerFinish__score">
        {`${answerPercentage}%`}
        <span className="p-quizAnswerFinish__scorePoint u-textS">
          みんなの正解率{totalPercentage}%
        </span>
      </p>
      <p className="p-quizAnswerFinish__scoreTitle u-textM">シェアする</p>
      <ShareButton
        state={{
          id: history.location.state.id,
          text: `${history.location.state.title}クイズに挑戦したよ！正解率${answerPercentage}%！`
        }}
      />
      <div className="p-quizAnswerFinish__result">
        <p className="p-quizAnswerFinish__scoreTitle u-textM">成績</p>
        <div className="p-quizAnswerFinish__resultBox">{resultText}</div>
      </div>
      <p className=" p-quizAnswerFinish__backBox u-textM">
        <Link to="/" className="button p-quizAnswerFinish__back">
          TOPへ戻る
        </Link>
      </p>
    </div>
  );
};

export default QuizAnswerFinish;
