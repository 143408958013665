import React from "react";
import Footer from "../Footer";

export const Policy = () => (
  <>
    <div className="p-policy">
      <h1 className="u-textL">プライバシーポリシー</h1>
      <p className="u-textS">
        みんなのスタディ(以下当サイト)は、個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、
        以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。
        なお、本プライバシーポリシーにおいて別段の定めがない限り、本プライバシーポリシーにおける用語の定義は、個人情報保護法の定めに従います。
      </p>
      <h2 className="p-policy__heading">1 個人情報の定義</h2>
      <p className="u-textS">
        本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義される個人情報を意味するものとします。
      </p>
      <h2 className="p-policy__heading">2 個人情報の利用目的</h2>
      当サイトは、個人情報を以下の目的で利用いたします。
      <ol className="p-policy__list u-textS">
        <li>「みんなのスタディ（https://min-study.com）」の提供のため</li>
        <li>当サイトに関するご案内、お問い合せ等への対応のため</li>
        <li>当サイトの商品、サービス等のご案内のため</li>
        <li>
          各種キャンペーン、アンケート等への応募受け付け及び対象者への連絡、発送のため{" "}
        </li>
        <li>メールマガジン、お知らせなどの情報配信のため</li>
        <li>当サイト上における広告配信のため</li>
        <li>当サイトを安全に運営するための調査、検証等のため</li>
        <li>
          当サイトに関する当サイトの規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
        </li>
        <li>当サイトに関する規約等の変更などを通知するため</li>
        <li>当サイトの改善、新サービスの開発等に役立てるため</li>
        <li>
          当サイトに関連して、個人を識別できない形式に加工した統計データを作成するため
        </li>
        <li>その他、上記利用目的に付随する目的のため</li>
      </ol>
      <h2 className="p-policy__heading">3 個人情報利用目的の変更</h2>
      <p className="u-textS">
        当サイトは、個人情報の利用目的を関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には個人情報の主体である個人（以下「本人」といいます。）に通知し又は公表します。
      </p>
      <h2 className="p-policy__heading">4 個人情報利用の制限</h2>
      <p className="u-textS">
        当サイトは、個人情報保護法その他の法令により許容される場合を除き、本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
      </p>
      <ol className="p-policy__list u-textS">
        <li>法令に基づく場合</li>
        <li>
          人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
        </li>
        <li>
          公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
        </li>
        <li>
          国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
        </li>
      </ol>
      <h2 className="p-policy__heading">5 個人情報の適正な取得</h2>
      <p className="u-textS">
        当サイトは、適正に個人情報を取得し、偽りその他不正の手段により取得しません。
      </p>
      <p className="u-textS">
        当サイトは、第三者から個人情報の提供を受けるに際しては、個人情報保護委員会規則で定めるところにより、次に掲げる事項の確認を行います。ただし、当該第三者による当該個人情報の提供が第4項各号のいずれかに該当する場合又は第7.1項各号のいずれかに該当する場合を除きます。
      </p>
      <ol className="p-policy__list u-textS">
        <li>
          当該第三者の氏名又は名称及び住所、並びに法人の場合はその代表者（法人でない団体で代表者又は管理人の定めのあるものの場合は、その代表者又は管理人）の氏名）
        </li>
        <li>当該第三者による当該個人情報の取得の経緯</li>
      </ol>
      <h2 className="p-policy__heading">6 個人情報の安全管理</h2>
      <p className="u-textS">
        当サイトは、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、当サイトの従業員に対し、必要かつ適切な監督を行います。また、当サイトは、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
      </p>
      <h2 className="p-policy__heading">7 第三者提供</h2>
      <ol className="p-policy__list u-textS">
        <li>
          当サイトは、第4項各号のいずれかに該当する場合を除くほか、あらかじめ本人の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。
        </li>
        <ol className="p-policy__list u-textS">
          <li>
            利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合
          </li>
          <li>
            合併その他の事由による事業の承継に伴って個人情報が提供される場合
          </li>
          <li>個人情報保護法の定めに基づき共同利用する場合</li>
        </ol>
        <li>
          当サイトをご利用いただいた場合、予約が成立した時点で、当サイトが出店者に対して、「本人に代わって」、個人情報を提供することについて同意していただいたものとみなします。出店者は、本人の個人情報を、そのサービスを本人に提供するためなどに利用しますが、当サイトは出店者における個人情報の取扱いについて責任を負うものではありません。
        </li>
        <li>
          当サイトは、個人情報を第三者に提供したときは、個人情報保護法第25条に従い、記録の作成及び保存を行います。
        </li>
        <li>
          当サイトは、第三者から個人情報の提供を受けるに際しては、個人情報保護法第26条に従い、必要な確認を行い、当該確認にかかる記録の作成及び保存を行うものとします。
        </li>
      </ol>
      <h2 className="p-policy__heading">8 個人情報の開示</h2>
      <p className="u-textS">
        当サイトは、本人から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、本人ご自身からのご請求であることを確認の上で、本人に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当サイトが開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり300円）を頂戴しておりますので、あらかじめ御了承ください。
      </p>
      <h2 className="p-policy__heading">9 個人情報の訂正等</h2>
      <p className="u-textS">
        当サイトは、本人から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、本人ご自身からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨を本人に通知します（訂正等を行わない旨の決定をしたときは、本人に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当サイトが訂正等の義務を負わない場合は、この限りではありません。
      </p>
      <h2 className="p-policy__heading">10 個人情報の利用停止等</h2>
      <p className="u-textS">
        当サイトは、本人から、本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合、又は個人情報がご本人の同意なく第三者に提供されているという理由により、個人情報保護法の定めに基づきその提供の停止（以下「提供停止」といいます。）を求められた場合において、そのご請求に理由があることが判明した場合には、本人ご自身からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等又は提供停止を行い、その旨を本人に通知します。但し、個人情報保護法その他の法令により、当サイトが利用停止等又は提供停止の義務を負わない場合は、この限りではありません。
      </p>
      <h2 className="p-policy__heading">11 匿名加工情報の取扱い</h2>
      <ol className="p-policy__list u-textS">
        <li>
          当サイトは、匿名加工情報（個人情報保護法第2条第9項に定めるものを意味し、同法第2条第10項に定める匿名加工情報データベース等を構成するものに限ります。以下同じ。）を作成するときは、個人情報保護委員会規則で定める基準に従い、個人情報を加工するものとします。
        </li>
        <li>
          当サイトは、匿名加工情報を作成したときは、個人情報保護委員会規則で定める基準に従い、安全管理のための措置を講じます。
        </li>
        <li>
          当サイトは、匿名加工情報を作成したときは、個人情報保護委員会規則で定めるところにより、当該匿名加工情報に含まれる個人に関する情報の項目を公表します。
        </li>
        <li>
          当サイトは、匿名加工情報（当サイトが作成したもの及び第三者から提供を受けたものを含みます。以下別段の定めがない限り同様とします。）を第三者に提供するときは、個人情報保護委員会規則で定めるところにより、あらかじめ、第三者に提供される匿名加工情報に含まれる個人に関する情報の項目及びその提供の方法について公表するとともに、当該第三者に対して、当該提供に係る情報が匿名加工情報である旨を明示します。
        </li>
        <li>
          当サイトは、匿名加工情報を取り扱うに当たっては、匿名加工情報の作成に用いられた個人情報に係る本人を識別するために、(1)匿名加工情報を他の情報と照合すること、及び(2)当該個人情報から削除された記述等若しくは個人識別符号又は個人情報保護法第36条第1項の規定により行われた加工の方法に関する情報を取得すること（(2)は第三者から提供を受けた当該匿名加工情報についてのみ）を行わないものとします。
        </li>
        <li>
          当サイトは、匿名加工情報の安全管理のために必要かつ適切な措置、匿名加工情報の作成その他の取扱いに関する苦情の処理その他の匿名加工情報の適正な取扱いを確保するために
          必要な措置を自ら講じ、かつ、当該措置の内容を公表するよう努めるものとします。
        </li>
      </ol>
      <h2 className="p-policy__heading">
        12 Cookie（クッキー）その他の技術の利用
      </h2>
      <ol className="p-policy__list u-textS">
        <li>
          当サイトは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当サイトによる当サイトの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、当サイトの一部の機能をご利用いただけなくなる場合があります。
        </li>
        <li>
          当サイトには別紙に示す当サイトが提携する企業による情報収集モジュールが組み込まれています。これに伴い、当該企業への利用者情報（通信サービス上での行動履歴、統計的なサイト利用情報などを意味しますが、個人識別はされず、匿名データの形で扱われます。）の提供を行います。
        </li>
      </ol>
      <h2 className="p-policy__heading">13 お問い合わせ</h2>
      <p className="u-textS">
        開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、
        <a href="./contact">問い合わせページ</a>にて受け付けております。
      </p>
      <h2 className="p-policy__heading">14 継続的改善</h2>
      <p className="u-textS">
        当サイトは、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。
      </p>
      <h2 className="p-policy__heading">（別紙）利用者情報を提供する企業</h2>
      <p className="u-textS">
        当該企業のプライバシーポリシーやクッキーに関する説明や無効化については当該企業をご覧ください。
      </p>
      <ul className="p-policy__list u-textS">
        <li>
          <a href="https://help.twitter.com/ja/safety-and-security/privacy-controls-for-tailored-ads">
            Twitter, Inc
          </a>
        </li>
        <li>
          <a href="https://support.google.com/analytics/answer/6004245?hl=ja">
            Google Analytics
          </a>
        </li>
        <li>
          <a href="https://policies.google.com/technologies/partner-sites?hl=ja">
            Google
          </a>
        </li>
      </ul>
    </div>
    <Footer />
  </>
);

export default Policy;
