import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import Home from "./components/Home";
import QuizCreate from "./components/QuizCreate";
import QuizTitle from "./components/QuizAnswer/QuizTitle";
import QuizFinish from "./components/QuizFinish";
import QuizAnswerFinish from "./components/QuizFinish/QuizAnswerFinish";
import QuizUpdate from "./components/QuizUpdate";
import MyPage from "./components/MyPage";
import NotFound from "./components/NotFound";
import ErrorBoundary from "./lib/ErrorBoundary";
import Kiyaku from "./components/Kiyaku";
import Policy from "./components/Policy";
import Contact from "./components/Contact";
import Notification from "./containers/Notification";
import ScrollToTop from "./components/ScrollToTop";

import logo from "./images/logo.png";

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <BrowserRouter>
          <ScrollToTop>
            <div className="App">
              <Notification />
              <header>
                <div className="l-app__headerWrapper">
                  <h1 className="l-app__headerLogo">
                    <Link to="/">
                      <img
                        src={logo}
                        alt="みんなのスタディ"
                        className="l-app__logo"
                      />
                    </Link>
                  </h1>
                  <div className="l-app__headerCreate">
                    <Link to="/mypage" className="l-app__createWrap">
                      <p className="textS l-app__headerMypageText">
                        マイページ
                      </p>
                    </Link>
                  </div>
                </div>
              </header>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/create" component={QuizCreate} />
                <Route path="/answer/:id" component={QuizTitle} />
                <Route path="/finish" component={QuizFinish} />
                <Route path="/answer_finish" component={QuizAnswerFinish} />
                <Route path="/update/:id" component={QuizUpdate} />
                <Route path="/mypage" component={MyPage} />
                <Route path="/kiyaku" component={Kiyaku} />
                <Route path="/policy" component={Policy} />
                <Route path="/contact" component={Contact} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </ScrollToTop>
        </BrowserRouter>
      </ErrorBoundary>
    );
  }
}

export default App;
