import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon
} from "react-share";
import CopyUrlButton from "../ShareButton/CopyUrlButton";

const hostName = window.location.host;

const ShareButton = state => {
  let shareUrl = `https://${hostName}/answer/${state.state.id}`;
  const shareText = `${state.state.text}`;
  return (
    <>
      <div className="p-shareButton">
        <FacebookShareButton
          url={shareUrl}
          quote={shareText}
          className="p-shareButtonContent"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl}
          title={shareText}
          className="p-shareButtonContent"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <LineShareButton
          url={shareUrl}
          title={shareText}
          className="p-shareButtonContent"
        >
          <LineIcon size={32} round />
        </LineShareButton>{" "}
        <br />
        <CopyUrlButton url={shareUrl} />
      </div>
    </>
  );
};

export default ShareButton;
