export const options = [
  { value: "trivia", label: "雑学" },
  { value: "Riddle", label: "なぞなぞ" },
  { value: "Language", label: "語学" },
  { value: "Programming", label: "プログラミング" },
  { value: "Examination", label: "受験・試験" },
  { value: "Business", label: "ビジネス" },
  { value: "Society", label: "歴史・地理・社会" },
  { value: "Science", label: "理系学問" },
  { value: "Culture", label: "文化・芸術" },
  { value: "Entertainment", label: "芸能" },
  { value: "Sport", label: "スポーツ" },
  { value: "Animation", label: "アニメ・ゲーム" }
];
