import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import firebase, { db } from "../../firebase";
import Loading from "../Loading";
import { isExist, hasValue } from "../../lib/Expansion";

const MyQuiz = ({ setTotalResponder, setTotalNice }) => {
  const [createQuizList, setCreateQuizList] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [responders, setResponders] = useState();
  const [nice, setNice] = useState();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        const docRef = db.collection("quiz");
        const items = [];
        docRef
          .where("userId", "==", user.uid)
          .get()
          .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              items.push(doc.data());
            });
          })
          .then(() => {
            setCreateQuizList({ items });
            setLoading(false);
          })
          .catch(function(error) {
            console.log("Error getting documents: ", error);
          });
      }
    });
  }, []);

  useEffect(() => {
    if (isExist(createQuizList)) {
      const collection = db
        .collection("responder")
        .orderBy("createdAt", "desc")
        .limit(10);
      const items = [];

      collection
        .get()
        .then(querySnapShot => {
          querySnapShot.forEach(doc => {
            items.push(doc.data());
          });
        })
        .then(() => {
          setResponders({ ...items });
        })
        .catch(error => {
          console.log("error:", error);
        });
    }
  }, [createQuizList]);

  useEffect(() => {
    const nice = db.collectionGroup("count");
    const items = [];
    nice
      .get()
      .then(querySnapShot => {
        querySnapShot.forEach(doc => {
          items.push(doc.data());
        });
      })
      .then(() => {
        setNice({ ...items });
      })
      .catch(error => {
        console.log("error:", error);
      });
  }, []);

  const listItem = createQuizList.items ? createQuizList.items : [];
  const list = listItem.map(list => (
    <li className="p-myPage__listBox" key={list.docId}>
      <div className="l-wrapper">
        <Link to={`/answer/${list.docId}`} className="p-myPage__listWrap">
          <div className="p-myPage__listInfo">
            <p className="p-myPage__listTitle u-textM">{list.title}</p>
            <p className="p-myPage__listDescription">{list.description}</p>
            <p className="p-myPage__listDetail--Visiter u-textXS">
              {hasValue(responders, list.docId)}
            </p>
            <p className="p-myPage__listDetail--Nice u-textXS">
              {hasValue(nice, list.docId)}
            </p>
            <Link
              to={`/update/${list.docId}`}
              className="p-myPage__listUpdate u-textXS"
            >
              編集する
            </Link>
          </div>
          <i className="fas fa-chevron-right"></i>
        </Link>
      </div>
    </li>
  ));

  useEffect(() => {
    // ToDo UseCallback使って書く。
    let countResponder = 0;
    let countNice = 0;
    listItem.forEach(list => {
      countResponder += hasValue(responders, list.docId);
      countNice += hasValue(nice, list.docId);
    });

    setTotalResponder(countResponder);
    setTotalNice(countNice);
  }, [
    listItem,
    setTotalResponder,
    setTotalNice,
    responders,
    nice,
    createQuizList
  ]);

  return (
    <>
      <p className="l-wrapper p-myPage__heading">作ったクイズ</p>
      {loading && <Loading boolean={isExist(listItem)} />}
      <ul className="p-myPage__list">{createQuizList.items ? list : ""}</ul>
      {!isExist(listItem) && (
        <p>
          クイズを作ってみよう！ <br /> 右上のつくるから作成してね！
        </p>
      )}
    </>
  );
};

export default MyQuiz;
