import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import firebase, { db } from "../../firebase";
import Loading from "../Loading";
import { isExist } from "../../lib/Expansion";

const MyNice = () => {
  const [createQuizList, setCreateQuizList] = useState([{}]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        const docRef = db.collectionGroup("users");
        const items = [];
        docRef
          .where("userId", "==", user.uid)
          .limit(5)
          .get()
          .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              items.push(doc.data());
            });
          })
          .then(() => {
            setCreateQuizList({ items });
            setLoading(false);
          })
          .catch(function(error) {
            console.log("Error getting documents: ", error);
          });
      }
    });
  }, []);

  const listItem = createQuizList.items ? createQuizList.items : [];
  const list = listItem.map(list => (
    <li className="p-myPage__listBox" key={list.quizId}>
      <div className="l-wrapper">
        <Link to={`/answer/${list.quizId}`} className="p-myPage__listWrap">
          <div className="p-myPage__listInfo">
            <p className="p-myPage__listTitle u-textM">{list.title}</p>
          </div>
          <i className="fas fa-chevron-right"></i>
        </Link>
      </div>
    </li>
  ));

  return (
    <>
      <p className="l-wrapper p-myPage__heading">いいねしたクイズ</p>
      {loading && <Loading boolean={isExist(listItem)} />}
      <ul className="p-myPage__list">{createQuizList.items ? list : ""}</ul>
    </>
  );
};

export default MyNice;
