import React from "react";

const QuizForm = ({ quiz, targetRemoveForm }) => {
  const onChangeQuestion = (e, index) => {
    const quizValue = Object.assign({}, quiz);
    quizValue[index]["question"] = e.target.value;
  };

  const onChangeAnswer = (e, index) => {
    const quizValue = Object.assign({}, quiz);
    quizValue[index]["answer"] = e.target.value;
  };

  const onChangeChoice1 = (e, index) => {
    const quizValue = Object.assign({}, quiz);
    quizValue[index]["choice1"] = e.target.value;
  };

  const onChangeChoice2 = (e, index) => {
    const quizValue = Object.assign({}, quiz);
    quizValue[index]["choice2"] = e.target.value;
  };

  const onChangeExplanation = (e, index) => {
    const quizValue = Object.assign({}, quiz);
    quizValue[index]["explanation"] = e.target.value;
  };

  const CreateForm = () => {
    return quiz.map((qz, index) => {
      return (
        <div key={index} className="quizForm">
          <div className="quizForm__heading">
            <p className="textL quizForm__questionNumber">{`第${index +
              1}問`}</p>
            <button
              className="textS quizForm__deleteButton"
              type="button"
              onClick={() => {
                targetRemoveForm(index);
              }}
            >
              ×削除する
            </button>
          </div>
          <div className="quizForm__quizContent">
            <p className="quizForm__label">問題文</p>
            <textarea
              className="form__quizTextarea textM"
              type="text"
              placeholder="例: 1996年の元号はなんでしょう？"
              defaultValue={quiz[index]["question"]}
              onChange={e => {
                onChangeQuestion(e, index);
              }}
            ></textarea>
            <p className="form__label form__description">解答</p>
            <input
              className="quizForm__input textM"
              type="text"
              defaultValue={quiz[index]["answer"]}
              onChange={e => {
                onChangeAnswer(e, index);
              }}
              placeholder="例: 平成"
            ></input>
            <p className="form__label form__description">ハズレ1</p>
            <input
              className="quizForm__input textM"
              type="text"
              defaultValue={quiz[index]["choice1"]}
              onChange={e => {
                onChangeChoice1(e, index);
              }}
              placeholder="例: 昭和"
            ></input>
            <p className="form__label form__description">ハズレ2</p>
            <input
              className="quizForm__input textM"
              type="text"
              defaultValue={quiz[index]["choice2"]}
              onChange={e => {
                onChangeChoice2(e, index);
              }}
              placeholder="例: 大正"
            ></input>
            <p className="form__label form__description">解説</p>
            <textarea
              className="form__textarea textM"
              defaultValue={quiz[index]["explanation"]}
              placeholder="例: 1996年は平成8年です！"
              onChange={e => {
                onChangeExplanation(e, index);
              }}
            ></textarea>
          </div>
        </div>
      );
    });
  };

  return <CreateForm />;
};

export default QuizForm;
