import React from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <>
    <div className="l-footer">
      <div className="l-footer__wrapper">
        <div className="l-footer__linkBox">
          <p className="l-footer__title">about us</p>
          <a
            href="https://media.min-study.com/"
            className="u-textS l-footer__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            みんスタ メディア
          </a>
          <Link to="/kiyaku" className="u-textS l-footer__link">
            <p className="l-footer__linkText">利用規約</p>
          </Link>
          <Link to="/policy" className="u-textS l-footer__link">
            <p className="l-footer__linkText">プライバシーポリシー</p>
          </Link>
          <Link to="/contact" className="u-textS l-footer__link">
            <p className="l-footer__linkText">お問い合わせ</p>
          </Link>
        </div>
        <a
          href="https://twitter.com/minnano_study"
          className="u-textS l-footer__copyLight"
          target="_blank"
          rel="noopener noreferrer"
        >
          ©minnano_study
        </a>
      </div>
    </div>
  </>
);

export default Footer;
