import React from "react";
import QuizList from "../QuizList";
import Search from "../Search";
import KeyVisual from "../KeyVisual";
import Ogp from "../Ogp";
import Footer from "../Footer";

const Home = () => (
  <>
    <Ogp />
    <KeyVisual />
    <div className="p-home">
      <Search />
      <QuizList />
    </div>
    <Footer />
  </>
);

export default Home;
