import React from "react";
import Select from "react-select";
import { options } from "../../../lib/CategoryOptions";

const Categories = ({ categories, setCategories }) => {
  const onChange = e => {
    e ? setCategories([...e]) : setCategories([]);
  };

  return (
    <>
      <p className="form__label form__description">クイズのジャンル</p>
      <Select
        className="form__categorySelectBox"
        placeholder="該当するジャンルを選んでください！"
        options={options}
        value={categories}
        onChange={e => {
          onChange(e);
        }}
        isMulti
      />
    </>
  );
};

export default Categories;
