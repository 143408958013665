import React from "react";
import Loader from "react-loader";

const Loading = ({ boolean }) => (
  <>
    <Loader loaded={boolean ? boolean : false} />
  </>
);

export default Loading;
