import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div>
    <h1>404</h1>
    <h2>ページが見つかりません！</h2>
    <p>
      <Link to="/">TOPへ戻る</Link>
    </p>
  </div>
);

export default NotFound;
