import React, { useEffect, useState } from "react";
import { isExist } from "../../lib/Expansion";
import { Link } from "react-router-dom";
import { options } from "../../lib/CategoryOptions";
import { db } from "../../firebase";

const SearchCategory = () => {
  const [queryResult, setQueryResult] = useState({});
  const [quizList, setQuizList] = useState({});
  const [resultTxt, setResultTxt] = useState("");

  const onClickSearchCategory = label => {
    const hasCategoryQuiz = listItem.filter(item => item.categories);
    const quizCategoryResult = hasCategoryQuiz.filter(item =>
      isExist(item.categories.filter(category => category.value === label))
    );
    isExist(quizCategoryResult)
      ? setResultTxt("")
      : setResultTxt("該当のジャンルは見つかりませんでした！作ってみてね！");
    setQuizList(quizCategoryResult);
  };

  useEffect(() => {
    const docRef = db.collection("quiz");
    const items = [];
    docRef
      .where("limited", "==", false)
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          items.push(doc.data());
        });
      })
      .then(() => {
        setQueryResult({ items });
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });
  }, []);

  const optionList = options.map(option => (
    <li key={option.value} className="p-searchCategory__tagWrap">
      <p
        className="p-searchCategory__tag u-textM"
        onClick={() => {
          onClickSearchCategory(option.value);
        }}
      >
        {option.label}
      </p>
    </li>
  ));
  const listItem = queryResult.items ? queryResult.items : [];
  const filterItem = isExist(quizList) ? quizList : [];
  const list = filterItem.map(list => (
    <li className="p-searchCategory__resultListBox" key={list.docId}>
      <div className="l-wrapper">
        <Link
          to={`/answer/${list.docId}`}
          className="p-searchCategory__resultListWrap"
        >
          <div className="p-searchCategory__resultListInfo">
            <p className="p-searchCategory__resultListTitle u-textM">
              {list.title}
            </p>
            <p className="p-searchCategory__resultListDescription">
              {list.description}
            </p>
          </div>
          <i className="fas fa-chevron-right"></i>
        </Link>
      </div>
    </li>
  ));
  return (
    <>
      <div className="l-wrapper p-searchCategory">
        <label htmlFor="keywords" className="p-searchCategory__label">
          カテゴリで探す
        </label>
        <ul className="p-searchCategory__list">{optionList}</ul>
        <p className="p-searchCategory__text u-textM">{resultTxt}</p>
      </div>
      <ul className="p-searchCategory__resultList">{list}</ul>
    </>
  );
};

export default SearchCategory;
