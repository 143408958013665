import React from "react";
import Form from "../Form";

const QuizCreate = () => (
  <>
    <div className="p-quizCreate">
      <div className="p-quizCreateWrap">
        <Form />
      </div>
    </div>
  </>
);

export default QuizCreate;
