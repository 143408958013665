export const isExist = val => {
  if (!val) {
    //null or undefined or ''(空文字) or 0 or false

    if (val !== 0 && val !== false) {
      return false;
    }
  } else if (typeof val == "object") {
    //array or object

    return Object.keys(val).length !== 0;
  }

  return true; //値は空である
};

export const roundDigit = (decimal, digit) => {
  return Math.floor(decimal * Math.pow(10, digit)) / Math.pow(10, digit);
};

export const hasValue = (object, target) => {
  // targetと紐付けて、対象のvalueが出るようにしたい。
  if (isExist(object)) {
    const targetObj = Object.values(getHashProperties(object));
    if (targetObj.indexOf(target) >= 0) {
      if (typeof targetObj[targetObj.indexOf(target) - 1] !== "number") {
        return targetObj[targetObj.indexOf(target) + 1];
      }
      return targetObj[targetObj.indexOf(target) - 1];
    }
  }
  return 0;
};

const getHashProperties = obj => {
  // TODO再帰的になるように変更
  let list = [];
  for (let key in obj) {
    if (typeof obj == "object")
      if (typeof obj[key] == "object") {
        const o = obj[key];
        for (let k in o) {
          list.push(o[k]);
        }
      }
  }
  return list;
};
