import React from "react";
import firebase from "../../firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

const uiConfig = {
  signInOptions: [firebase.auth.TwitterAuthProvider.PROVIDER_ID]
};

const Auth = () => (
  <>
    <div className="l-wrapper">
      <h3 className="p-auth__heading">ログインする</h3>
      <StyledFirebaseAuth
        className="p-auth__button"
        uiConfig={uiConfig}
        firebaseAuth={firebase.auth()}
      />
      <h3 className="p-auth__heading">ログインでできること</h3>
      <p className="p-auth__text">
        ・自分の作ったクイズが編集できる！
        <br />
        ・今まで解いたクイズの履歴が残る
        <br />
        などなど…
      </p>
    </div>
  </>
);

export default Auth;
