import React from "react";
import { connect } from "react-redux";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch } from "react-redux";

const Notification = state => {
  const dispatch = useDispatch();
  const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({ type: "CLOSE_NOTIFICATION" });
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={state.NotificationReducer.isOpen}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" color="info">
          {state.NotificationReducer.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(Notification);
