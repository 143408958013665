import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import QuizForm from "./QuizForm";
import Categories from "./Parts/Categories";
import firebase, { db } from "../../firebase/index";

const Form = () => {
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [quiz, setQuiz] = useState([{}]);
  const [creater, setCreater] = useState("");
  const [limited, setLimited] = useState(false);
  const [categories, setCategories] = useState([]);
  const [userId, setUserId] = useState("");

  const [values, setValues] = useState({});

  const onSubmit = (e, values) => {
    e.preventDefault();
    if (!validation()) return false;
    const docId = pushData(values);
    history.push({
      pathname: "/finish",
      state: {
        title: values.title,
        text: `${values.title}クイズを作成したよ！何問解けるかな！？`,
        id: docId
      }
    });
  };

  const addForms = () => {
    setQuiz([...quiz, {}]);
  };

  const targetRemoveForm = index => {
    if (quiz.length <= 1) {
      return alert("これ以上削除できません！");
    }
    const newForms = quiz;
    newForms.splice(index, 1);
    setQuiz([...newForms]);
  };

  const pushData = values => {
    const docId = db.collection("quiz").doc().id;

    db.collection("quiz")
      .doc(docId)
      .set({
        docId: docId,
        title: values.title,
        description: values.description,
        creater: values.creater,
        quiz: quiz,
        categories: categories,
        limited: limited,
        userId: userId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
    return docId;
  };

  const validation = () => {
    let errors = [];
    if (values.creater === "") errors.push("作成者が登録されていません");
    if (values.title === "") errors.push("タイトルが入力されていません");
    if (values.description === "")
      errors.push("クイズの説明が入力されていません");
    if (values.quiz) {
      values.quiz.map(function callback(qz, index) {
        if (!qz.question) errors.push(`${index + 1}問目のクイズの問題がないよ`);
        if (!qz.answer) errors.push(`${index + 1}問目のクイズの答えがないよ`);
        if (!qz.choice1)
          errors.push(`${index + 1}問目のクイズの選択肢1がないよ`);
        if (!qz.choice2)
          errors.push(`${index + 1}問目のクイズの選択肢2がないよ`);
        return null;
      });
    }
    if (errors.length > 0) alert(errors);

    return errors.length > 0 ? false : true;
  };

  useEffect(() => {
    setValues({
      title: title,
      description: description,
      creater: creater,
      quiz: quiz,
      categories: categories,
      limited: limited,
      userId: userId
    });
  }, [title, description, creater, quiz, categories, limited, userId]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        setUserId(user.uid);
      }
    });
  }, []);

  return (
    <>
      <form
        className="form"
        onSubmit={e => {
          onSubmit(e, values);
        }}
      >
        <p className="form__title wrapper">クイズをつくる</p>
        <div className="form__name wrapper">
          <label className="form__label">クイズ作成者</label>
          <input
            className="form__input textM"
            value={creater}
            placeholder="例: クイズ太郎"
            onChange={e => {
              setCreater(e.target.value);
            }}
          ></input>
        </div>
        <div className="form__quizName">
          <p className="form__label">クイズ名</p>
          <input
            className="form__input textM"
            type="text"
            value={title}
            placeholder="例: 新年クイズ"
            onChange={e => {
              setTitle(e.target.value);
            }}
          ></input>
          <p className="form__label form__description">クイズの説明</p>
          <textarea
            className="form__textarea textM"
            value={description}
            placeholder="例: 2020年の令和にまつわるクイズです！"
            onChange={e => {
              setDescription(e.target.value);
            }}
          ></textarea>
          <Categories categories={categories} setCategories={setCategories} />
          <label>
            <p className="form__label form__description">
              クイズの公開範囲
              <span className="textXS form__annotation">
                ※ONにすると検索やTOPに表示されなくなります。
              </span>
            </p>
            <p className="textM form__openRange">
              <input
                className="checkbox"
                type="checkbox"
                value={limited}
                checked={limited}
                onChange={() => {
                  setLimited(!limited);
                }}
              />
              <span className="checkboxIcon"></span>
              クイズを限定公開する
            </p>
          </label>
        </div>
        <QuizForm quiz={quiz} targetRemoveForm={targetRemoveForm} />
        <div className="form__addWrap">
          <button type="button" onClick={addForms} className="form__add textM">
            クイズを追加する
          </button>
        </div>
        <div className="form__completeWrap">
          <button type="submit" className="form__completeButton textL">
            作ったクイズを送信する
          </button>
        </div>
      </form>
    </>
  );
};

export default Form;
