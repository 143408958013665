import React from "react";
import { Link } from "react-router-dom";
import ShareButton from "../ShareButton";

const QuizFinish = history => (
  <>
    <div className="l-wrapper p-quizFinish__wrap">
      <p className="p-quizFinish__Title">完了！</p>
      <h2 className="p-quizFinish__quizName">
        {history.location.state ? history.location.state.title : ""}
      </h2>
      <p className="p-quizFinish__Title">シェアする</p>
      <ShareButton state={history.location.state} />
      <p className="u-textM p-quizFinish__backBox">
        <Link to="/" className="button p-quizFinish__back">
          TOPへ戻る
        </Link>
      </p>
    </div>
  </>
);

export default QuizFinish;
