import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Ogp = ({ title, id, description }) => {
  useEffect(() => {
    if (title) {
      window.setTimeout(() => {
        window.gtag("config", "G-Y3WM567DYE", {
          page_path: window.location.pathname
        });
      });
    }
  }, [title]);

  const pageTitle = title ? title : "みんなのスタディ";
  const url = id
    ? `https://min-study.com/answer/${id}`
    : "https://min-study.com";
  const pageDescription = description
    ? description
    : "様々なクイズを作って遊んで楽しもう！";

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      {pageTitle ? (
        <meta
          property="og:image"
          content={`https://res.cloudinary.com/dkayqpnqy/image/upload/l_text:Sawarabi%20Gothic_36_text_align_center:${pageTitle},co_rgb:101010,w_580,c_fit/v1590490952/OGP_quiz_btuls7.png`}
        />
      ) : (
        <></>
      )}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <meta property="og:description" content={pageDescription} />
    </Helmet>
  );
};

export default Ogp;
