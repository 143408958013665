import React from "react";
import SearchCategory from "./SearchCategory";
import SearchKeyWords from "./SearchKeyWords";

const Search = () => {
  return (
    <>
      <div>
        <SearchKeyWords />
        <SearchCategory />
      </div>
    </>
  );
};

export default Search;
