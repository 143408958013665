import React from "react";
import { useDispatch } from "react-redux";

const CopyUrlButton = ({ url }) => {
  const dispatch = useDispatch();
  const onClickCopyUrl = () => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    dispatch({
      type: "SET_NOTIFICATION",
      variant: "success",
      message: "URLをコピーしました！"
    });
  };

  return (
    document.queryCommandSupported("copy") && (
      <div className="p-shareButton__url">
        <button
          type="button"
          onClick={onClickCopyUrl}
          className="c-button u-textM p-shareButton__urlButton"
        >
          URLをコピー
        </button>
      </div>
    )
  );
};

export default CopyUrlButton;
