import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import QuizAnswer from "./QuizAnswer";
import Ogp from "../Ogp";
import Loading from "../Loading";
import Nice from "../Nice";
import { isExist } from "../../lib/Expansion";

import firebase, { db } from "../../firebase/index";

const QuizTitle = params => {
  const history = useHistory();
  const [challenge, setChallenge] = useState(false);
  const [quizData, setQuizData] = useState({});
  const param = params.match.params;
  const id = param.id;
  const [responder, setResponder] = useState({});
  const [answerScore, setAnswerScore] = useState();
  const [answerPercentage, setAnswerPercentage] = useState();
  const [totalPercentage, setTotalPercentage] = useState();

  const countTotalResponder = () => {
    const docRef = db.collection("responder").doc(id);
    docRef.get().then(doc => {
      if (!doc.exists) {
        docRef.set({
          docId: id,
          responder: 1,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });
      } else {
        docRef.update({
          responder: firebase.firestore.FieldValue.increment(1),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });
      }
    });
  };

  useEffect(() => {
    const docRef = db.collection("quiz").doc(id);
    docRef.get().then(doc => {
      if (doc.exists) {
        setQuizData(doc.data());
      } else {
        //なければ404ページへ
        history.push("/404");
      }
    });
  }, [history, id]);

  useEffect(() => {
    const getTotalResponder = () => {
      const docRef = db.collection("responder").doc(id);
      docRef.get().then(doc => {
        if (doc.exists) {
          setResponder(doc.data());
        }
      });
    };
    getTotalResponder();
  }, [id]);

  useEffect(() => {
    const getAnswerScore = () => {
      const items = [];
      const scoreRef = db
        .collection("quiz")
        .doc(id)
        .collection("answerScore");
      scoreRef
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            items.push(doc.data());
          });
        })
        .then(() => {
          setAnswerScore(items);
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    };
    getAnswerScore();
  }, [id]);

  useEffect(() => {
    const mathAnswerPercentage = () => {
      if (isExist(answerScore)) {
        let denominators = 0;
        let numerators = 0;
        const minorityFirst = 1; //小数点第一位まで計算
        const answerPercentage = answerScore.map(score => {
          const denominator = score.false + score.true;
          denominators += denominator;
          const numerator = score.true;
          numerators += numerator;
          const percentage = (numerator / denominator) * 100;
          return (
            Math.floor(percentage * Math.pow(10, minorityFirst)) /
            Math.pow(10, minorityFirst)
          );
        });
        const totalPercentage = (numerators / denominators) * 100;
        const totalMinorityPercentage =
          Math.floor(totalPercentage * Math.pow(10, minorityFirst)) /
          Math.pow(10, minorityFirst);
        if (!isNaN(totalMinorityPercentage)) {
          setTotalPercentage(totalMinorityPercentage);
        }
        if (!isNaN(totalMinorityPercentage)) {
          setAnswerPercentage(answerPercentage);
        }
      }
    };
    mathAnswerPercentage();
  }, [answerScore, totalPercentage]);

  return (
    <>
      <Ogp title={quizData.title} id={id} description={quizData.description} />
      {"title" in quizData ? "" : <Loading />}
      {!challenge ? (
        <div className="l-wrapper p-quizAnswer">
          <div className="p-quizAnswer__mainTitle">
            <h2 className="p-quizAnswer__title">{quizData.title}</h2>
            <p className="p-quizAnswer__mainTitleText u-textS">
              {quizData.description}
            </p>
          </div>
          <div className="p-quizAnswer__info">
            <p className="p-quizAnswer__text u-textS">
              <i className="fas fa-user-edit p-quizAnswer__icon"></i>作成者
              {quizData.creater}
            </p>
            <p className="p-quizAnswer__text u-textS">
              <i className="fas fa-user p-quizAnswer__icon"></i>
              今までの挑戦者 {responder.responder ? responder.responder : 0}人
            </p>
            <p className="p-quizAnswer__text u-textS">
              <i className="fas fa-bullseye p-quizAnswer__icon"></i>
              {totalPercentage && `みんなの正解率 ${totalPercentage}%`}
            </p>
          </div>
          <Nice id={id} title={quizData.title} />
          {"title" in quizData ? (
            <button
              type="button"
              onClick={() => {
                setChallenge(true);
                countTotalResponder();
              }}
              className="c-button p-quizAnswer__button u-textL"
            >
              チャレンジする！
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        <QuizAnswer
          quizData={quizData}
          answerPercentage={answerPercentage}
          totalPercentage={totalPercentage}
        />
      )}
    </>
  );
};

export default QuizTitle;
