import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const QuizAnswer = ({ quizData, answerPercentage, totalPercentage }) => {
  const [answer, setAnswer] = useState(false);
  const [answerCount, setAnswerCount] = useState(0);
  const [correct, setCorrect] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [randomInt, setRandomInt] = useState(0);
  const [quizFalseWord, setQuizFalseWord] = useState("");
  const [quizTrueWord, setQuizTrueWord] = useState("");
  const [quizTrueText, setQuizTrueText] = useState("");
  const [quizFalseText, setQuizFalseText] = useState("");
  const [quizExplanation, setQuizExplanation] = useState("");
  const [selectClass1, setSelectClass1] = useState("");
  const [selectClass2, setSelectClass2] = useState("");
  const [selectClass3, setSelectClass3] = useState("");
  const [answerData, setAnswerData] = useState([]);
  const [resultColorClass, setResultColorClass] = useState("");
  const history = useHistory();

  const onSubmit = () => {
    history.push({
      pathname: "/answer_finish",
      state: {
        answerCount: answerCount,
        correctCount: correctCount,
        id: quizData.docId,
        title: quizData.title,
        quiz: quizData.quiz,
        answerData: answerData,
        totalPercentage: totalPercentage
      }
    });
  };

  const onNextButton = () => {
    setAnswer(false);
    setQuizFalseWord("");
    setQuizTrueWord("");
    setSelectClass1("");
    setSelectClass2("");
    setSelectClass3("");
    if (answerLength !== answerCount + 1) {
      setAnswerCount(answerCount + 1);
    }
  };

  const data = quizData ? quizData.quiz : [];
  const answerLength = data.length;

  const answerQuestion = () => {
    setAnswer(true);
    setQuizFalseWord("×");
    setQuizTrueWord("◯");
    setQuizTrueText("正解！");
    setQuizFalseText("不正解…");
    setQuizExplanation(data[answerCount].explanation);
  };

  const TrueAnswer = () => (
    <button
      className={`c-button p-quizAnswer__answerButton u-textL ${selectClass1}`}
      disabled={answer}
      onClick={() => {
        answerQuestion();
        setCorrectCount(correctCount + 1);
        setAnswerData([...answerData, true]);
        setCorrect(true);
        setResultColorClass("p-quizAnswer__true");
        setSelectClass1("p-quizAnswer__selectedTrue"); //押したものが正解だった時
      }}
    >
      {quizTrueWord}
      {data[answerCount].answer}
    </button>
  );

  const FalseAnswer1 = () => (
    <button
      className={`c-button p-quizAnswer__answerButton u-textL ${selectClass2}`}
      disabled={answer}
      onClick={() => {
        answerQuestion();
        setCorrect(false);
        setAnswerData([...answerData, false]);
        setResultColorClass("p-quizAnswer__false");
        setSelectClass2("p-quizAnswer__selectedFalse"); //押したものが不正解だった時
      }}
    >
      {quizFalseWord}
      {data[answerCount].choice1}
    </button>
  );

  const FalseAnswer2 = () => (
    <button
      className={`c-button p-quizAnswer__answerButton u-textL ${selectClass3}`}
      disabled={answer}
      onClick={() => {
        answerQuestion();
        setCorrect(false);
        setAnswerData([...answerData, false]);
        setResultColorClass("p-quizAnswer__false");
        setSelectClass3("p-quizAnswer__selectedFalse"); //押したものが不正解だった時
      }}
    >
      {quizFalseWord}
      {data[answerCount].choice2}
    </button>
  );

  useEffect(() => {
    setRandomInt(getRandomInt(6));
  }, [answerCount]);

  //良いコンポーネントランダム出現の方法があれば置き換えたい。
  const RandomQuestion = () => {
    const displayNum = randomInt;
    if (displayNum === 5) {
      return (
        <>
          <TrueAnswer />
          <FalseAnswer1 />
          <FalseAnswer2 />
        </>
      );
    } else if (displayNum === 4) {
      return (
        <>
          <FalseAnswer1 />
          <TrueAnswer />
          <FalseAnswer2 />
        </>
      );
    } else if (displayNum === 3) {
      return (
        <>
          <FalseAnswer2 />
          <FalseAnswer1 />
          <TrueAnswer />
        </>
      );
    } else if (displayNum === 2) {
      return (
        <>
          <TrueAnswer />
          <FalseAnswer2 />
          <FalseAnswer1 />
        </>
      );
    } else if (displayNum === 1) {
      return (
        <>
          <FalseAnswer1 />
          <FalseAnswer2 />
          <TrueAnswer />
        </>
      );
    } else {
      return (
        <>
          <FalseAnswer2 />
          <FalseAnswer1 />
          <TrueAnswer />
        </>
      );
    }
  };

  const getRandomInt = max => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  return (
    <>
      <div className="l-wrapper p-quizAnswer">
        <h2 className="p-quizAnswer__subHeading">問題{answerCount + 1} </h2>
        <p className="p-quizAnswer__Problemtitle u-textL">
          {data[answerCount].question}
        </p>
        <p className="p-quizAnswer__Percentage u-textXS">
          {answerPercentage &&
            `この問題の正解率 ${answerPercentage[answerCount]}%`}
        </p>
        <RandomQuestion />
        <div className="p-quizAnswer__nextAction">
          {answer ? (
            answerLength === answerCount + 1 ? (
              <>
                <p
                  className={`p-quizAnswer__resultText u-textL ${resultColorClass}`}
                >
                  {correct ? quizTrueText : quizFalseText}
                </p>
                <p className="p-quizAnswer__resultAnswer">
                  答えは、
                  <span className="p-quizAnswer__resultAnswerEmphasis">
                    {data[answerCount].answer}
                  </span>
                </p>
                <p className="p-quizAnswer__resultAnswerText">
                  {quizExplanation}
                </p>
                <button
                  onClick={onSubmit}
                  className="c-button p-quizAnswer__result u-textM"
                >
                  結果を見る
                </button>
              </>
            ) : (
              <>
                <p
                  className={`p-quizAnswer__resultText u-textL ${resultColorClass}`}
                >
                  {correct ? quizTrueText : quizFalseText}
                </p>
                <p className="p-quizAnswer__resultAnswer">
                  答えは、
                  <span className="p-quizAnswer__resultAnswerEmphasis">
                    {data[answerCount].answer}
                  </span>
                </p>
                <p className="p-quizAnswer__resultAnswerText">
                  {quizExplanation}
                </p>
                <button
                  onClick={onNextButton}
                  className="c-button p-quizAnswer__result u-textM"
                >
                  次の問題へ進む
                </button>
              </>
            )
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};

export default QuizAnswer;
