import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase, { db } from "../../firebase/index";
import UpdateForm from "../Form/UpdateForm";
import NotFound from "../NotFound";
import { isExist } from "../../lib/Expansion";
import Loading from "../Loading";

const QuizUpdate = params => {
  const history = useHistory();
  const [quizData, setQuizData] = useState({});
  const [userId, setUserId] = useState("");
  const param = params.match.params;
  const id = param.id;

  useEffect(() => {
    const docRef = db.collection("quiz").doc(id);
    docRef.get().then(doc => {
      if (doc.exists) {
        setQuizData(doc.data());
      } else {
        //なければ404ページへ
        history.push("/404");
      }
    });
  }, [history, id]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId("notLogin");
      }
    });
  }, []);
  if (userId === quizData.userId) {
    return (
      <>
        <div className="quizCrate">
          {isExist(quizData) ? <UpdateForm quizData={quizData} /> : <Loading />}
        </div>
      </>
    );
  }
  return (
    <>{isExist(quizData) && isExist(userId) ? <NotFound /> : <Loading />}</>
  );
};

export default QuizUpdate;
