import React from "react";

const MyContribution = ({ totalResponder, totalNice }) => {
  return (
    <>
      <div className="p-myPage__myContribution">
        <div className="p-myPage__total">
          <p className="p-myPage__totalNumber">{totalNice}</p>
          <p className="p-myPage__totalText u-textS">合計いいね数</p>
        </div>
        <div className="p-myPage__total">
          <p className="p-myPage__totalNumber">{totalResponder}</p>
          <p className="p-myPage__totalText u-textS">合計PV数</p>
        </div>
      </div>
    </>
  );
};

export default MyContribution;
