import React from "react";
import Footer from "../Footer";

export const Kiyaku = () => (
  <>
    <div className="p-kiyaku">
      <h1 className="u-textL">利用規約</h1>
      <p className="u-textS">
        この利用規約（以下，「本規約」といいます。）は，
        <a href="https://twitter.com/minnano_study">@minnano_study</a>
        （以下，「運営者」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。
        登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
      </p>

      <h2 className="p-kiyaku__heading">第1条（適用）</h2>
      <p className="u-textS">
        本規約は，ユーザーと運営者との間の本サービスの利用に関わる一切の関係に適用されるものとします。
      </p>

      <h2 className="p-kiyaku__heading">第2条（利用登録）</h2>
      <p className="u-textS">
        登録希望者が運営者の定める方法によって利用登録を申請することによって，利用登録が完了するものとします。
      </p>
      <p className="u-textS">
        運営者は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
      </p>
      <ol className="p-kiyaku__list u-textS">
        <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
        <li>本規約に違反したことがある者からの申請である場合</li>
        <li>
          反社会的勢力等（暴力団，暴力団員，右翼団体，反社会的勢力，その他これに準ずる者を意味します。）である，または資金提供その他を通じて反社会的勢力等の維持，運営もしくは経営に協力もしくは関与する等反社会的勢力との何らかの交流もしくは関与を行っていると運営者が判断した場合
        </li>
        <li>その他，運営者が利用登録を相当でないと判断した場合</li>
      </ol>
      <h2 className="p-kiyaku__heading">第3条（ログイン情報の管理）</h2>
      <p className="u-textS">
        ユーザーは，自己の責任において，本サービスのログインを管理するものとします。
      </p>
      <p className="u-textS">
        ユーザーは，いかなる場合にも，ログインを第三者に譲渡または貸与することはできません。
      </p>
      <p className="u-textS">
        運営者は，TiwtterのOauthによるログイン情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。
      </p>
      <p className="u-textS">
        運営者は，ユーザーのTwitterのログイン情報の漏洩によっての本サービスへのなりすましログインに対しての保証責任は負いません。
      </p>
      <h2 className="p-kiyaku__heading">第4条（Twitterアプリの許可権限）</h2>
      <p className="u-textS">
        運営者は,本サービスへのログインを行うための情報取得手段としてTwitterのAPIによるログイン情報の取得のため,
        ユーザーにアプリによるTwitterの情報の参照権限を要求します。
      </p>
      <p className="u-textS">
        本サービスはTwitterアカウント情報の読み取り権限をユーザから許可を取得します。
      </p>
      <p className="u-textS">
        これによって知り得た情報は別の目的への利用及び第三者への開示などは行わないものとします。
      </p>
      <h2 className="p-kiyaku__heading">第5条（禁止事項）</h2>
      <p className="u-textS">
        ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
      </p>
      <ol className="p-kiyaku__list u-textS">
        <li>法令または公序良俗に違反する行為</li>
        <li>犯罪行為に関連する行為</li>
        <li>
          運営者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
        </li>
        <li>運営者のサービスの運営を妨害するおそれのある行為</li>
        <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
        <li>他のユーザーに成りすます行為</li>
        <li>
          運営者のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
        </li>
        <li>
          運営者，本サービスの他の利用者または第三者の知的財産権，肖像権，プライバシー，名誉その他の権利または利益を侵害する行為
        </li>
        <li>
          過度に暴力的な表現，露骨な性的表現，人種，国籍，信条，性別，社会的身分，門地等による差別につながる表現，自殺，自傷行為，薬物乱用を誘引または助長する表現，その他反社会的な内容を含み他人に不快感を与える表現を，投稿または送信する行為
        </li>
        <li>
          営業，宣伝，広告，勧誘，その他営利を目的とする行為（運営者の認めたものを除きます。），性行為やわいせつな行為を目的とする行為，面識のない異性との出会いや交際を目的とする行為，他のお客様に対する嫌がらせや誹謗中傷を目的とする行為，その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
        </li>
        <li>宗教活動または宗教団体への勧誘行為</li>
        <li>その他，運営者が不適切と判断する行為</li>
      </ol>
      <h2 className="p-kiyaku__heading">第6条（本サービスの提供の停止等）</h2>
      <p className="u-textS">
        運営者は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
      </p>
      <ol className="p-kiyaku__list u-textS">
        <li>
          本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
        </li>
        <li>
          地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
        </li>
        <li>コンピュータまたは通信回線等が事故により停止した場合</li>
        <li>その他，運営者が本サービスの提供が困難と判断した場合</li>
      </ol>
      <p className="u-textS">
        運営者は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害について，理由を問わず一切の責任を負わないものとします。
      </p>
      <h2 className="p-kiyaku__heading">第7条（著作権）</h2>
      <p className="u-textS">
        ユーザーは，自ら著作権等の必要な知的財産権を有するか，または必要な権利者の許諾を得た文章，画像や映像等の情報のみ，本サービスを利用し，投稿または編集することができるものとします。
      </p>
      <p className="u-textS">
        ユーザーが本サービスを利用して投稿または編集した文章，画像，映像等の著作権については，当該ユーザーその他既存の権利者に留保されるものとします。
        ただし，運営者は，本サービスを利用して投稿または編集された文章，画像，映像等を利用できるものとし，ユーザーは，この利用に関して，著作者人格権を行使しないものとします。
      </p>
      <p className="u-textS">
        前項本文の定めるものを除き，本サービスおよび本サービスに関連する一切の情報についての著作権およびその他知的財産権はすべて運営者または運営者にその利用を許諾した権利者に帰属し，
        ユーザーは無断で複製，譲渡，貸与，翻訳，改変，転載，公衆送信（送信可能化を含みます。），伝送，配布，出版，営業使用等をしてはならないものとします。
      </p>
      <h2 className="p-kiyaku__heading">第8条（利用制限および登録抹消）</h2>
      <p className="u-textS">
        運営者は，以下の場合には，事前の通知なく，投稿データを削除し，ユーザーに対して本サービスの全部もしくは一部の利用を制限しまたはユーザーとしての登録を抹消することができるものとします。
      </p>
      <ol className="p-kiyaku__list u-textS">
        <li>本規約のいずれかの条項に違反した場合</li>
        <li>登録事項に虚偽の事実があることが判明した場合</li>
        <li>1年間以上本サービスの利用がない場合</li>
        <li>
          運営者からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合
        </li>
        <li>第2条第2項各号に該当する場合</li>
        <li>その他，運営者が本サービスの利用を適当でないと判断した場合</li>
      </ol>

      <p className="u-textS">
        運営者は，本条に基づき運営者が行った行為によりユーザーに生じた損害について，一切の責任を負いません。
      </p>
      <h2 className="p-kiyaku__heading">第9条（保証の否認および免責事項）</h2>
      <p className="u-textS">
        運営者は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
      </p>
      <p className="u-textS">
        運営者は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。
      </p>
      <p className="u-textS">
        運営者は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた連絡または紛争等について一切責任を負いません。
      </p>
      <h2 className="p-kiyaku__heading">第10条（サービス内容の変更等）</h2>
      <p className="u-textS">
        運営者は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
      </p>
      <h2 className="p-kiyaku__heading">第11条（利用規約の変更）</h2>
      <p className="u-textS">
        運営者は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。
      </p>
      <h2 className="p-kiyaku__heading">第12条（通知または連絡）</h2>
      <p className="u-textS">
        ユーザーと運営者との間の通知または連絡は，運営者の定める方法によって行うものとします。
      </p>
      <h2 className="p-kiyaku__heading">第13条（準拠法・裁判管轄）</h2>
      <p className="u-textS">
        本規約の解釈にあたっては，日本法を準拠法とします。
      </p>
      <p className="u-textS">
        本サービスに関して紛争が生じた場合には，運営者の本店所在地を管轄する裁判所を専属的合意管轄とします。
      </p>
    </div>
    <Footer />
  </>
);

export default Kiyaku;
