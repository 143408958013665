import React from "react";
import { Link } from "react-router-dom";

const KeyVisual = () => (
  <>
    <div className="p-kv">
      <div className="p-kv__wrapper">
        <p className="p-kv__mainCopy">
          みんなで学習を
          <br />
          シェアしよう
        </p>
        <p className="p-kv__subCopy">作って学べる、解いて学べる。</p>
        <div className="p-kv__buttonContent">
          <Link to="/create" className="p-kv__button">
            <p className="p-kv__buttonText u-textM">
              問題を<span className="p-kv__buttonTextEmphasis">作る</span>
            </p>
          </Link>
          <a href="#search" className="p-kv__button">
            <p className="p-kv__buttonText u-textM">
              問題を<span className="p-kv__buttonTextEmphasis">解く</span>
            </p>
          </a>
        </div>
      </div>
    </div>
  </>
);

export default KeyVisual;
