export const firebaseConfig = {
  apiKey: "AIzaSyAkMscDkalFs80MS2oU97ZM5JMTtf-kBJ4",
  authDomain: "quiz-sakusaku.firebaseapp.com",
  databaseURL: "https://quiz-sakusaku.firebaseio.com",
  projectId: "quiz-sakusaku",
  storageBucket: "quiz-sakusaku.appspot.com",
  messagingSenderId: "734304494065",
  appId: "1:734304494065:web:6626ab9e0c19885e84e450",
  measurementId: "G-Y3WM567DYE"
};
