import React, { useState, useEffect } from "react";
import firebase, { db } from "../../firebase";

const Nice = ({ id, title }) => {
  const [niceCount, setNiceCount] = useState(0);

  useEffect(() => {
    const getNice = () => {
      const countRef = db
        .collection("quiz")
        .doc(id)
        .collection("count")
        .doc("Nice");
      countRef.get().then(count => {
        if (count.exists) {
          const data = count.data();
          setNiceCount(data.count);
        }
      });
    };

    getNice();
  }, [id]);

  const countNice = number => {
    const countRef = db
      .collection("quiz")
      .doc(id)
      .collection("count")
      .doc("Nice");
    countRef.get().then(count => {
      if (count.exists) {
        const data = count.data();
        setNiceCount(parseInt(data.count) + number);
        countRef.update({
          count: firebase.firestore.FieldValue.increment(number)
        });
      } else {
        countRef.set({
          docId: id,
          count: 1
        });
        setNiceCount(1);
      }
    });
  };

  const onClickAction = () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const docRef = db
          .collection("quiz")
          .doc(id)
          .collection("users")
          .doc(user.uid);
        docRef.get().then(doc => {
          if (doc.exists) {
            countNice(-1);
            docRef
              .delete()
              .then(function() {
                console.log("Document successfully deleted!");
              })
              .catch(function(error) {
                console.error("Error removing document: ", error);
              });
          } else {
            countNice(1);
            docRef.set({
              userId: user.uid,
              quizId: id,
              title: title
            });
          }
        });
      } else {
        alert("いいねはログインしないとできません！");
      }
    });
  };
  return (
    <>
      <button
        className="p-nice__button"
        onClick={() => {
          onClickAction();
        }}
      >
        <i className="fas fa-thumbs-up p-nice__icon"></i>
        いいね！({niceCount && niceCount})
      </button>
    </>
  );
};

export default Nice;
